<template>
  <div id="app">
    <Heads></Heads>
    <div id="app-body">
      <router-view></router-view>
    </div>
    <Footers></Footers>
    <div class="commonDiaLog" v-if="dialogVisible">
      <div class="commonDiaLog-container">
        <div class="commonDiaLog-title">
          <img src="../src/assets/images/commonDiaLog_title.png" alt="">
        </div>
        <div class="commonDiaLog-body">
          <p>银行针对中标企业有宽松信用贷款政策</p>
          <div class="commonDiaLog-money">最高额度<span>1000</span>万</div>
          <div class="commonDiaLog-btn" @click="closeUserBidSuccessAlert">申请</div>
        </div>
        <div class="commonDiaLog-close">
          <img src="../src/assets/images/commonDiaLog_close.png" alt="" @click="closeUserBidSuccessAlert('close')">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Heads from "./components/TabBar.vue";
import Footers from "./components/Footers";
export default {
  components: {
    Heads,
    Footers,
  },
  name: "App",
  data() {
    return {
      dialogVisible: false,
      url:''
    };
  },
  created() {
    if (!this.$cookies.get("TSESSIONID")) {
      location.href = `${process.env.VUE_APP_LOGIN_URL
        }/?returnUrl=${encodeURIComponent(location.href)}`;
    }
    this.showBidAlert();
  },
  methods: {
    async showBidAlert() {
      const res = await this.$getAPI.getUserBidSuccessAlertStatus();
      if (res.code === "0") {
        this.url = res.data.url
        this.dialogVisible = true
      }
    },
    async closeUserBidSuccessAlert(from) {
      await this.$getAPI.closeUserBidSuccessAlert()
      this.dialogVisible = false
      if (from === 'close') return
      setTimeout(() => {
        window.open(this.url)
      }, 10);
    }
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  height: 100vh;

  &-body {
    position: relative;
    width: 1200px;
    margin: 0 auto;
  }
}

.el-pager,
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev {
  padding: 0 5px;
  margin: 0;
}

.commonDiaLog {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10;

  &-container {
    width: 380px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-title {
    img {
      width: 100%;
      display: block;
    }
  }

  &-body {
    background-color: #fff;
    background-image: url(../src/assets/images/commonWinbid_bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 20px 20px 30px;
    border-radius: 0 0 8px 8px;
    text-align: center;

    p {
      font-family: PingFangSC, PingFang SC;
      font-size: 20px;
      color: #323232;
      line-height: 50px;
      text-align: center;
      font-weight: 600;
    }
  }

  &-money {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 25px;
    color: #323232;
    line-height: 50px;
    text-align: center;

    span {
      color: #DE2D12;
    }
  }

  &-btn {
    width: 103px;
    height: 40px;
    background: linear-gradient(180deg, #FF5252 0%, #C80000 100%);
    border-radius: 5px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 40px;
    margin: 10px auto 0;
    cursor: pointer;
  }

  &-close {
    margin-top: 20px;
    text-align: center;
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
}
</style>
